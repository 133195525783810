import { writable } from 'svelte/store';

// Export the store so we can use it in any component
export const videoPopout = writable(null);

export async function popoutVideo(url, mods = {}) {
	let resolve;

	const promise = new Promise((_resolve) => {
		resolve = _resolve;
	});

	/**
	 *  Possible Mods:
	 *  - TBD
	 */

	videoPopout.set({
		url,
		mods,
		resolve,
	});

	return promise;
}
