<script>
	// https://www.w3schools.com/css/css_tooltip.asp
	export let useTooltipSlot = false;
	export let tooltipPosition = 'right';
	export let tooltipText = '';
	export let disabled = false;

	const getPosClass = (pos) => {
		switch (pos) {
			case 'right':
				return 'tooltip-right';
			case 'left':
				return 'tooltip-left';
			case 'top':
				return 'tooltip-top';
			case 'bottom':
				return 'tooltip-bottom';
			default:
				return 'tooltip-right';
		}
	};
</script>

<div class="tooltip">
	<slot name="element-with-tooltip" />
	{#if !disabled}
		<span class="tooltiptext text-sm {getPosClass(tooltipPosition)}">
			{#if useTooltipSlot}
				<slot name="tooltip-text" />
			{:else}
				{@html tooltipText}
			{/if}
		</span>
	{/if}
</div>

<style>
	.tooltip {
		position: relative;
		display: inline-block;
		/* border-bottom: 1px dotted black; */
	}

	.tooltip .tooltiptext {
		visibility: hidden;
		width: 200px;
		max-width: calc(100vw - 20px);
		background-color: #eef1f4;
		color: #2a3240;
		text-align: center;
		padding: 0.5rem;
		max-height: calc(100vh - 20px);
		overflow-y: auto;

		border-width: 1px;
		border-color: #dee1e7;
		border-radius: 6px;

		/* Position the tooltip */
		position: absolute;
		z-index: 1000;
	}

	.tooltip-right {
		top: 50%;
		transform: translateY(-50%);
		left: calc(100% + 8px);
	}

	.tooltip-left {
		top: 50%;
		transform: translateY(-50%);
		right: calc(100% + 8px);
		left: auto;
	}

	.tooltip-top {
		bottom: calc(100% + 8px);
		left: 50%;
		transform: translateX(-50%);
		top: auto;
	}

	.tooltip-bottom {
		top: calc(100% + 8px);
		left: 50%;
		transform: translateX(-50%);
	}

	/* Ensure tooltip stays in viewport */
	@media screen and (max-width: 480px) {
		.tooltip .tooltiptext {
			width: calc(100vw - 40px);
			left: 50%;
			transform: translateX(-50%);
			right: auto;
		}
		
		.tooltip-right,
		.tooltip-left {
			top: calc(100% + 8px);
		}
	}

	.tooltip:hover .tooltiptext {
		visibility: visible;
	}
</style>
