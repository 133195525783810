export const truncateText = (text, limit, addEllipsis = true) => {
	if (!text) return '';
	if (text.length < limit) return text;

	if (addEllipsis) {
		return text.substring(0, limit) + '...';
	}

	return text.substring(0, limit);
};

export const cleanLongStrings = (text) => {
	// Used when words are too long to fit in a container
	// replace underscores and hyphens with soft hyphens
	if (!text?.length) return text;

	let str = text;

	// For long strings we want to replace underscores with soft hyphens to allow line breaks
	if (str.length > 15) str = hypthenizeUnderscores(str);

	// Replace hyphens with soft hyphens
	str = softHyphenize(str);

	return str;
};

export const softHyphenize = (text) => {
	if (!text) return undefined;
	return text?.replace(/([-])/g, '\u00AD');
};

export const hypthenizeUnderscores = (text) => {
	if (!text) return undefined;
	return text?.replace(/([_])/g, '\u00AD');
};

export function textToBase64(text) {
	if (typeof window === 'undefined') {
		// Server-side (Node.js)
		return Buffer.from(text, 'utf-8').toString('base64');
	} else {
		// Client-side (Browser)
		return btoa(unescape(encodeURIComponent(text)));
	}
}
