<script>
	import { videoPopout } from '$lib/stores/popoutVideoStores';

	let show = false;
	let videoSrc = '';

	videoPopout.subscribe((val) => {
		if (val !== null) {
			show = true;
			videoSrc = val.url;
		} else {
			show = false;
			videoSrc = '';
		}
	});

	export const closeModal = () => {
		videoPopout.set(null);
		show = false;
	};
</script>

<div
	class:hidden={!show}
	class="fixed top-0 right-0 left-0 grid h-full content-center items-center justify-center overflow-y-auto overflow-x-hidden bg-gray-200/50 sm:h-full md:inset-0 {$$props.class}"
	style="z-index: 9999;"
	on:click={closeModal}
>
	<div class="relative grid h-full  w-full content-center px-4">
		<div class="grid-content-center relative rounded-lg bg-white shadow dark:bg-gray-700">
			<!-- svelte-ignore a11y-img-redundant-alt -->
			<video src={videoSrc} controls class="w-full h-full max-w-[400px]">
				<track kind="captions" src="" label="English" />
			</video>
		</div>
	</div>
</div>
